import React from 'react';
import { navigate } from 'gatsby';
import Seo from "../../components/seo"

class TokenEconomyPage extends React.Component {
    componentDidMount = () => {
        navigate('/tokeneconomy/overview');
    }
    
    render = () => <Seo title="Token Economy" />;
};

export default TokenEconomyPage;
